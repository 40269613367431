import React, { useState, useContext } from "react";
import TextArea from "../../../components/TextArea";
import ListadoContext from "../../context/ListadoContext";
import { parseISOString } from "../../../context/FuncionesContext";

import DatePicker from "react-datepicker";
import { TextField } from "@mui/material";

const InputOnSubmit = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  type = cab.type,
  id_elemento,
}) => {
  const { addSideData } = useContext(ListadoContext);

  const [value, setValue] = useState(data[campokey]);
  const [lastValue, setLastvalue] = useState(data[campokey]);

  const label = (() => {
    if (cab.label_alias) {
      return data[cab.label_alias];
    }
    const _label = data[cab.id_a + "_label"] ?? cab.label;
    if (!_label || _label === "") return null;
    return _label;
  })();

  const clave_envio = (() => {
    if (
      data[cab.id_a + "_clave_envio"] &&
      data[cab.id_a + "_clave_envio"] !== ""
    )
      return data[cab.id_a + "_clave_envio"];
    if (cab.clave_envio && cab.clave_envio !== "") return cab.clave_envio;
    return cab.id_a;
  })();

  const className = data[cab.id_a + "_className"] ?? cab.className;

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const handleChange = async (e) => {
    console.log(e.target.value);
    try {
      const valor = e.target.value;
      const className = data[cab.id_a + "_className"] ?? cab.className;

      if (valor === null || lastValue?.toString() === valor.toString().trim())
        return;

      addSideData({ clave_envio: clave_envio, valor });

      // setLastvalue(() => value);
      setValue(() => valor);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const style = (() => {
    if (data[cab.update_id_alias]) {
      return {
        textAlign: cab.align ?? "left",
        borderColor: "darkgreen",
        borderWidth: "3px",
        fontSize: "0.8rem",
      };
    }
    return {
      textAlign: cab.align ?? "left",
      borderColor: "grey",
      fontSize: "0.8rem",
    };
  })();

  let Componente = (
    <TextArea
      value={value ?? ""}
      setValue={setValue}
      onEnter={handleChange}
      style={style}
      id={id_elemento}
    />
  );

  if (type === "number") {
    Componente = (
      <TextField
        id={id_elemento}
        type="number"
        onChange={handleChange}
        value={value}
        defaultValue={value}
        // label={label}
        // InputLabelProps={{
        //   style: {
        //     fontSize: "1em",
        //     top:"-10px"
        //   },
        // }}
        inputProps={{
          style: { textAlign: "right", fontSize: "1em", padding: "0.2em" },
        }}
        variant="outlined"
      />
    );
  }

  if (type === "textarea") {
    Componente = (
      <TextArea
        value={value ?? ""}
        setValue={setValue}
        onEnter={handleChange}
        style={style}
        id={id_elemento}
      />
    );
  }

  if (!type || type === "text") {
    Componente = (
      <TextField
        id={id_elemento}
        type="text"
        onChange={handleChange}
        defaultValue={value}
        inputProps={{
          style: {
            textAlign: "center",
            fontSize: "1em",
            padding: "0.2em",
          },
        }}
        variant="outlined"
      />
    );
  }

  if (type === "fecha_hora") {
    Componente = (
      <>
        <DatePicker
          onChange={(f) =>
            handleChange({ target: { value: parseISOString(f) } })
          }
          dateFormat={cab.fecha_formato ?? "dd-MM-yyyy hh:mm"}
          selected={value}
          withPortal
          showYearDropdown
          showTimeSelect
        />
        {cab.permite_null ? (
          <div
            onClick={() => handleChange(null)}
            className="columna_input_date_null"
          >
            x
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  if (type === "fecha") {
    Componente = (
      <>
        <DatePicker
          onChange={(f) =>
            handleChange({ target: { value: parseISOString(f) } })
          }
          className="input_fecha"
          dateFormat={cab.fecha_formato ?? "dd/MM/yyyy"}
          selected={value}
          withPortal
          showYearDropdown
        />
        {cab.permite_null ? (
          <div
            onClick={() => handleChange(null)}
            className="columna_input_date_null"
          >
            x
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <div className="tarjeta_grid_item_label_item d-flex listado_boton_inputOnSubmit">
      {!!label ? (
        <>
          <div style={{ alignSelf: "baseline", margin: "auto 4px" }} className={`${className} vista_label p-0`}>
            {label}
          </div>
        </>
      ) : (
        <></>
      )}
      {Componente}
    </div>
  );
};

export default InputOnSubmit;

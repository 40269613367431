import React, { useContext, useEffect, useState, useReducer } from "react";

import ABMContext from "./context/ABMContext";
import ABMReducer, { initialState } from "./context/ABMReducer";
import PantallaContext from "../context/PantallaContext";
import FuncionesContext, { getAtributo } from "../context/FuncionesContext";
import { FileProvider } from "./context/FileContext";
import { useRefStore } from "../../..";
import { addsql } from "../context/PantallaReducer";

const ABMProvider = ({
  configuracion,
  id,
  children,
  nollamar,
  params,
  modalProps,
  qsBody = {},
}) => {
  const { configuraciones_ref, loadingPantalla, configuraciones_padre } =
    useContext(PantallaContext);
  const { getConfiguracion, ABMSubmit, FormularioSubmit, requestErrorHandler } =
    useContext(FuncionesContext);

  const [state, dispatch] = useReducer(ABMReducer, initialState);

  const [refState, setRefState] = useRefStore();

  const [loading, setLoading] = useState(true);

  const id_a = configuracion.opciones.id_a;

  const { parametro_nombre } = configuracion.opciones;
  useEffect(() => {
    if (params && parametro_nombre && parametro_nombre !== "") {
      qsBody.id = params[parametro_nombre];
    }
  });

  useEffect(() => {
    dispatch({
      type: "SET_CABECERAS",
      payload: configuracion.cabeceras,
    });
    dispatch({
      type: "SET_DATOS",
      payload: configuracion.datos,
    });
    dispatch({
      type: "SET_OPCIONES",
      payload: configuracion.opciones,
    });
    dispatch({
      type: "ADD_CONFIGURACIONES_REF",
      payload: configuracion.cabeceras,
    });

    configuracion.datos.forEach((dato) => {
      configuracion.cabeceras.forEach((cab) => {
        if (cab.clave_envio && cab.clave_envio.trim() !== "") {
          dispatch({
            type: "SET_FORMULARIO_VALOR",
            payload: { id_a: cab.clave_envio, valor: dato[cab.id_a] },
          });
        }
        return dispatch({
          type: "SET_FORMULARIO_VALOR",
          payload: { id_a: cab.id_a, valor: dato[cab.id_a] },
        });
      });
    });

    // AGREGA UPDATE_ID SI VIENE EN DATOS Y NO EN RUTA
    const _id = getAtributo(
      "update_id",
      configuracion.datos,
      configuracion.opciones
    );
    const regex = /^-?\d+$/;
    if (typeof _id === "number" || regex.test(_id)) {
      if (_id || id) {
        dispatch({
          type: "SET_FORMULARIO_VALOR",
          payload: { id_a: "id", valor: _id || id },
        });
      }
    }
    ///////////////////////////////////////////////////

    setFormularioObligatorio(configuracion, dispatch);

    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
    setLoading(false);
  }, [
    loadingPantalla,
    id,
    configuracion.cabeceras,
    configuracion.datos,
    configuracion.opciones,
  ]);

  useEffect(() => {
    configuracion.datos.forEach((dato) => {
      configuracion.cabeceras.forEach((cab) => {
        dispatch({
          type: "SET_FORMULARIO_INICIAL",
          payload: {
            id_a: cab.id_a,
            valor: dato[cab.id_a] ?? dato[cab.id_a + "_default"] ?? cab.default,
          },
        });
      });
    });
  }, [configuracion.cabeceras, configuracion.datos]);

  const callAPI = async ({ params }) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    setLoading(true);
    return await getConfiguracion(
      id_a,
      qsBody,
      params,
      configuraciones_padre[id_a]
    ).then((res) => {
      if (res.status >= 400) {
        requestErrorHandler(res);
      }
      dispatch({
        type: "SET_CABECERAS",
        payload: res.data.cabeceras,
      });
      dispatch({
        type: "SET_DATOS",
        payload: res.data.datos,
      });
      dispatch({
        type: "SET_OPCIONES",
        payload: res.data.opciones,
      });
      dispatch({
        type: "ADD_CONFIGURACIONES_REF",
        payload: res.data.cabeceras,
      });
      setRefState({ sql: addsql(res.data.sql, refState.sql) });

      return res.data;
    });
  };

  function verificarValores(obj, keys) {
    console.log("verificarValores", obj, keys);
    for (const key of keys) {
      if (
        !(key in obj) ||
        obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === ""
      ) {
        dispatch({
          type: "SET_FORMULARIO_ERROR",
          payload: { id_a: key, valor: true },
        });
        return false;
      }
    }
    return true;
  }

  const guardarAPI = async ({ params }) => {
    setLoading(true);
    // AGREGA UPDATE_ID SI VIENE EN DATOS Y NO EN RUTA
    const _id = getAtributo(
      "update_id",
      configuracion.datos,
      configuracion.opciones
    );
    const regex = /^-?\d+$/;
    if (typeof _id === "number" || regex.test(_id)) {
      if (_id || id) {
        dispatch({
          type: "SET_FORMULARIO_VALOR",
          payload: { id_a: "id", valor: _id || id },
        });
      }
    }
    ///////////////////////////////////////////////////
    return await ABMSubmit({
      id_a,
      qsBody,
      params,
      opciones: configuracion.opciones,
      setLoading,
    })
      .then((res) => {
        if (res.status >= 400) {
          requestErrorHandler(res);
        }
        setLoading(false);
        return res;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const enviarFormulario = async ({ params }) => {
    setLoading(true);
    return await FormularioSubmit({
      id_a,
      qsBody,
      params,
      opciones: configuracion.opciones,
      setLoading,
    })
      .then((res) => {
        if (res.status >= 400) {
          requestErrorHandler(res);
        }

        setLoading(false);
        return res;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const submit = { ABM: guardarAPI, Formulario: enviarFormulario };

  useEffect(() => {
    if (configuracion.opciones.tipo.nombre === "Formulario") return;

    if (params && parametro_nombre) {
      if (id != params[parametro_nombre]) {
        callAPI({ params }).then(async (configuracion) => {
          await Promise.all(
            configuracion.datos.map(async (dato) => {
              await Promise.all(
                configuracion.cabeceras.map(async (cab) => {
                  dispatch({
                    type: "SET_FORMULARIO_VALOR",
                    payload: { id_a: cab.id_a, valor: dato[cab.id_a] },
                  });
                })
              );
            })
          );

          dispatch({
            type: "SET_LOADING",
            payload: false,
          });
          setLoading(false);
        });
      }
    }
    //useEffect para actualizar datos a control
    if (configuraciones_ref[id_a] === 1) return;

    if (nollamar) return;
    console.log("call api ??");
    callAPI({});
  }, [configuraciones_ref[id_a]]);

  return (
    <FileProvider>
      <ABMContext.Provider
        value={{
          datos: state.datos,
          cabeceras: state.cabeceras,
          opciones: state.opciones,
          valorFormulario: state.formularioValor,
          formularioInicial: state.formularioInicial,
          configuraciones_cab: state.configuraciones_cab,
          modalProps,
          state,
          id,
          loading: loading,
          params,
          id_a,
          qsBody,
          ABMDispatch: dispatch,
          Dispatch: dispatch,
          callAPI,
          submit: submit[configuracion.opciones.tipo.nombre],
          obligatorios: state.obligatorio,
        }}
      >
        {state.loading && loading ? (
          <div
            style={{
              backgroundColor: "rgba(200,150,100,0.5)",
              position: "fixed",
              width: "100vw",
              height: "100vh",
              zIndex: 2000,
              cursor: "wait",
            }}
          >
            CARGANDO....
          </div>
        ) : (
          children
        )}
      </ABMContext.Provider>
    </FileProvider>
  );
};

export default ABMProvider;

function setFormularioObligatorio(configuracion, dispatch) {
  const checkAndDispatch = (item) => {
    // Si permite_null es "n", disparamos la acción
    if (item.permite_null === "n") {
      dispatch({
        type: "SET_FORMULARIO_OBLIGATORIO",
        payload: { id_a: item.id_a },
      });
    }

    // Si el item tiene hijos, recursivamente llamamos a checkAndDispatch en cada hijo
    if (item.sc_hijos && item.sc_hijos.length > 0) {
      item.sc_hijos.forEach((child) => checkAndDispatch(child));
    }
  };

  // Empezamos el proceso para cada cabecera en la configuración
  configuracion.cabeceras.forEach((cab) => checkAndDispatch(cab));
}

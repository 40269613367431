import React, { useState, useContext, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@material-ui/core/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core";

import Botonera from "../Botonera";
import SwitchFiltros from "./SwitchFiltros";
import ListadoContext from "../context/ListadoContext";
import "./filtros.scss";
import FuncionesContext from "../../context/FuncionesContext";
import Switch from "@material-ui/core/Switch";

// ver como reemplazar este makeStyles
const useStyle = makeStyles({
  bloqueAccordion: {
    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root": {
      width: "100%",
    },
    "& .MuiAccordionSummary-root ": {
      padding: "0 16px",
    },
    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "30px",
      height: "35px",
      paddingTop: "15px",
      paddingBottom: "6px",
    },
  },
  bloqueAccordionAbierto: {
    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root": {
      width: "100%",
    },
    "& .MuiAccordionSummary-root ": {
      padding: "0 16px",
    },
    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "0px",
      height: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    margin: "0 !important",
  },
  typo: {
    fontStyle: "italic",
  },
});

const Filtros = (props) => {
  const {
    filtros,
    setFilter: aplicarFiltros,
    filtroActivo,
    loading,
    opcionesListado,
    listadoBotones,
    handleCancelar,
    Dispatch,
    id_global,
  } = useContext(ListadoContext);

  const { opciones, setMapa, data } = props;

  const { insertar } = useContext(FuncionesContext);

  const [filtrosAAplicar, setFiltrosAAplicar] = useState(filtroActivo);
  const [expanded, setExpanded] = useState(false);
  const [requeridos, setRequeridos] = useState([]);
  const [error, setError] = useState({});

  opciones.filtros = filtrosAAplicar;
  opciones.dispatch = Dispatch;
  opciones.id_global = id_global;

  useEffect(() => {
    filtros
      .filter((f) => f.componente !== "hidden")
      .forEach((f) => {
        if (f.permite_null === "n") {
          setRequeridos((s) => {
            const ns = s.concat(f.id_a);
            return ns;
          });
        }

        setError((s) => {
          return { ...s, [f.id_a]: false };
        });
      });
  }, []);

  useEffect(() => {
    setFiltrosAAplicar(() => filtroActivo);
    // console.log("filtros activos en filtros", filtroActivo, Date.now()); // ACA HAY UN ERROR DE LOGICA REACT Radio Button Loco viene por aca?
  }, [filtroActivo]);

  const validar = () => {
    let filtrosValidados = [];

    let opcionales = filtros
      .filter((f) => f.opcionales_null === "s")
      .map((f) => f.id_a);

    if (requeridos.length > 0) {
      requeridos.forEach((r) => {
        const filtroAValidar = filtros.filter((f) => f.id_a === r).pop();

        if (!filtrosAAplicar[r] || filtrosAAplicar[r] === null) {
          filtrosValidados[r] = true;
          return;
        }
        if (
          typeof filtrosAAplicar[r] === "string" &&
          filtrosAAplicar[r].trim() === ""
        ) {
          filtrosValidados[r] = true;
          return;
        }
        if (
          filtroAValidar.componente === "fecha" &&
          JSON.parse(filtrosAAplicar[r]).filter((f) => f).length !== 2
        ) {
          filtrosValidados[r] = true;
          return;
        }
        filtrosValidados[r] = false;
      });
    }

    const filtrosConValoresValidos = Object.keys(filtrosValidados).filter(
      (fv) => filtrosValidados[fv] === false
    );
    if (filtrosConValoresValidos.some((fcvv) => opcionales.includes(fcvv))) {
      Object.keys(filtrosValidados).forEach((k) => {
        if (opcionales.includes(k)) return (filtrosValidados[k] = false);
        return;
      });
    }
    // console.log(filtrosValidados)
    setError(() => filtrosValidados);
    return (
      Object.keys(filtrosValidados).filter((f) => filtrosValidados[f])
        .length === 0
    );
  };

  const handleSubmit = async () => {
    if (loading) return;

    if (validar()) {
      if (opcionesListado.configuracionDeUsuario?.guardar_filtros === "s") {
        const SISTEMA_GUARDAR_FILTROS = "SISTEMA_GUARDAR_FILTROS";
        if (!opcionesListado.configuracionesDeListado) return;

        const { datos, cabeceras } =
          opcionesListado.configuracionesDeListado[0].configuraciones.find(
            (c) => c.opciones.tipo.id === 6
          );

        await insertar({
          valor: filtrosAAplicar,
          id_a: SISTEMA_GUARDAR_FILTROS,
          insert_ids: (() => {
            return datos[0][
              cabeceras.find((cab) => cab.id_a === SISTEMA_GUARDAR_FILTROS)
                .insert_ids_alias
            ];
          })(),
        });
      }
      // setExpanded((s) => false);
      aplicarFiltros({ filtrosAAplicar, quienLlama: "Boton Submit Filtro" });

      return;
    }
  };

  
  const hayObligatorios =
    filtros.filter((f) => f.permite_null === "n").length > 0;
  const filtrosVisibles = filtros.filter(
    (f) => f.componente !== "hidden"
  ).length;

  const botonesSuperiores = listadoBotones.filter(
    (b) => b.listado_boton_inferior !== "s"
  );

  const classes = useStyle();

  return (
    <div 
    className={`${filtrosVisibles === 0 ? "m-0" : ""}`}>
      {filtrosVisibles > 0 ? (
        <Paper
          elevation="4"
          style={{ margin: "0.3rem", boxShadow: "none" }}
          className={`${expanded ? classes.paperAbierto : ""} container_filtros borde-tarjeta`}
        >
          <div className="filtro_grid" style={{ paddingBottom: "0.3rem" }}>
            <div className="filtro_grid_inputs_grid">
              {filtros
                .filter((f) => f.filtro_avanzado !== "s")
                ?.sort((a, b) => a.orden - b.orden)
                .map((f) => {
                  return (
                    <SwitchFiltros
                      {...f}
                      key={f.id_a}
                      filtrosAAplicar={filtrosAAplicar}
                      setFiltrosAAplicar={setFiltrosAAplicar}
                      requeridos={requeridos}
                      setRequeridos={setRequeridos}
                      error={error}
                      setError={setError}
                      context={ListadoContext}
                      handleSubmit={handleSubmit}
                    />
                  );
                })}
            </div>
          </div>
          {/* expanded true */}
          {filtros.filter((f) => f.filtro_avanzado === "s").length > 0 ? (
            <Accordion
              defaultExpanded={false}
              expanded={expanded}
              onChange={() => setExpanded((s) => !s)}
              style={{boxShadow: "none"}}
              className={`acordion ${
                expanded
                  ? classes.bloqueAccordionAbierto
                  : classes.bloqueAccordion
              }`}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1bh-header"
              >
                {expanded ? (
                  <></>
                ) : (
                  <Typography variant="caption" className={classes.typo}>
                    Más opciones de busqueda...
                  </Typography>
                )}
              </AccordionSummary>
              <div className="filtro_grid" style={{ marginTop: 0 }}>
                <div className="filtro_grid_inputs_grid">
                  {filtros
                    .filter((f) => f.filtro_avanzado === "s")
                    ?.sort((a, b) => a.orden - b.orden)
                    .map((f) => {
                      return (
                        <SwitchFiltros
                          {...f}
                          key={f.id_a}
                          filtrosAAplicar={filtrosAAplicar}
                          setFiltrosAAplicar={setFiltrosAAplicar}
                          requeridos={requeridos}
                          setRequeridos={setRequeridos}
                          error={error}
                          setError={setError}
                          context={ListadoContext}
                        />
                      );
                    })}
                </div>
              </div>
            </Accordion>
          ) : (
            <></>
          )}
          {hayObligatorios ? (
            <div
              id="campos_obligatorios"
              style={{
                padding: "0 1.5rem ",
                display: "flex",
                fontSize: "0.6rem",
                fontStyle: "italic",
                filter: "opacity(0.8)",
              }}
            >
              <span style={{ color: "red" }}>*</span>{" "}
              <div style={{ marginRight: "0.8rem" }}>Campo requerido</div>
              <span style={{ color: "red" }}>**</span> Campo requerido opcional
            </div>
          ) : (
            <></>
          )}
        </Paper>
      ) : (
        <></>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Botonera
          handleSubmit={handleSubmit}
          handleCancelar={handleCancelar}
          loading={loading}
          buscar={filtrosVisibles > 0}
          cancelar={filtrosVisibles > 0}
          acciones={botonesSuperiores}
          context={ListadoContext}
          submit={opcionesListado.submit_button}
          submit_texto={opcionesListado.submit_texto}
          submit_handleSubmit={opcionesListado.submit_button_handleSubmit}
          data={data}
        />
        {opcionesListado.usar_mapa === "s" ? (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Switch
                onChange={() => setMapa((s) => !s)}
                color="primary"
                className="list-toggle"
              />
              <div
                style={{
                  fontSize: "0.6rem",
                  textAlign: "center",
                  marginTop: "-0.5rem",
                }}
              >
                Mapa
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Filtros;
